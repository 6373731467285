<template>
  <div class="detail">
    <ChatRoom v-bind:username="username" />
  </div>
</template>

<script>
import ChatRoom from "@/components/ChatRoom";
export default {
  name: "Detail",
  props: {
    username: {
      type: Text
    }
  },
  metaInfo: {
    title: 'Hot Tranny Tube',
    // https://alligator.io/vuejs/vue-seo-tips/,
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { name: 'description', content: 'The best online tranny cam models from around the world', id: 'desc' }
    ]
  },
  components: {
    ChatRoom
  }
}
</script>

<style scoped>

</style>
