<template>
<div class="chat-room">
  <iframe :src='roomLink'></iframe>
</div>
</template>

<script>
export default {
  name: "ChatRoom",
  props: {
    username: {
      type: Text
    }
  },
  computed: {
    roomLink: function () {
      return "https://chaturbate.com/in/?tour=9oGW&amp;campaign=pY2En&amp;track=embed&amp;room="+this.username+"&amp;bgcolor=white"
    }
  }
}
</script>

<style scoped>
  .chat-room > iframe {
    width: 100%;
    height: 100vh;
  }
</style>
